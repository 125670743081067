import { template as template_057a4d5836414039823104ed4b0ec1d3 } from "@ember/template-compiler";
const FKLabel = template_057a4d5836414039823104ed4b0ec1d3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
