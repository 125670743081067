import { template as template_b30d126af8594dbd9182a5fe088df8c4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b30d126af8594dbd9182a5fe088df8c4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
